import Metrics from '@/plugins/metrics'
import {
  frequencyItems,
  happenedToOptions,
} from '@/views/Reports/ReportForm/constants'
import { mapActions } from 'vuex'

export default {
  name: 'StepTwo',
  props: {
    report: {
      type: Object,
      required: true,
    },
    experimentName: {
      type: String,
      required: true,
    },
    displayOriginalFlow: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      happenedToOptions,
      frequencyItems,
      misconducts: [],
      happenedAtOther: false,
      maxLength: 25,
    }
  },

  computed: {
    isRecurrent() {
      return this.report.recurrent
    },

    currentDate() {
      return new Date().toJSON().split('T')[0]
    },

    hasCustomMisconduct() {
      if (this.displayOriginalFlow) return false
      return this.report.misconducts.some((item) => item.localeKey === 'OTHER')
    },

    sortMisconducts() {
      const sortedMisconducts = this.misconducts.map((el) => {
        const localeKey = this.$t(el.localeKey)
        return { ...el, localeKey }
      })

      sortedMisconducts.sort((a, b) => a.localeKey.localeCompare(b.localeKey))

      return sortedMisconducts
    },
  },

  async mounted() {
    const { source, channel, anonymous } = this.report
    const payloadMetrics = {
      source,
      channel,
      anonymous,
    }

    Metrics.track('Report Step Two Started', payloadMetrics)

    if (!this.displayOriginalFlow) {
      this.misconducts = await this.getMisconducts()
    }
  },

  watch: {
    'report.happenedAt': {
      deep: true,
      immediate: true,
      async handler() {
        if (this.report.happenedAt) {
          this.happenedAtOther = null
        }
      },
    },
  },

  methods: {
    ...mapActions(['getMisconducts']),
    resetInput() {
      this.report.happenedAt = null
      this.$refs.inputHappenedAt.value = ''
    },
  },
}
