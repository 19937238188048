const happenedToOptions = [
  {
    id: 'ME',
    localeKey: 'REPORT_INVOLVED_ANSWER_ME',
  },
  {
    id: 'SOMEONE_ELSE',
    localeKey: 'REPORT_INVOLVED_ANSWER_OTHERS',
  },
  {
    id: 'ME_AND_SOMEONE_ELSE',
    localeKey: 'REPORT_INVOLVED_ANSWER_ME_OTHERS',
  },
]

const frequencyItems = [
  {
    id: 'once',
    localeKey: 'REPORT_FREQUENCY_ANSWER_FIRST_TIME',
    value: false,
  },
  {
    id: 'more-than-once',
    localeKey: 'REPORT_FREQUENCY_ANSWER_HAPPENED_BEFORE',
    value: true,
  },
]

const companyAreas = [
  'product',
  'sales',
  'marketing',
  'hr',
  'compliance',
  'manager',
  'atm',
  'technology',
  'financial',
  'legal',
]

const dayTime = [
  {
    id: 'morning',
    localeKey: 'REPORT_SHIFT_ANSWER_MORNING',
    value: 'MORNING',
  },
  {
    id: 'afternoon',
    localeKey: 'REPORT_SHIFT_ANSWER_AFTERNOON',
    value: 'AFTERNOON',
  },
  {
    id: 'night',
    localeKey: 'REPORT_SHIFT_ANSWER_NIGHT',
    value: 'NIGHT',
  },
]

export { happenedToOptions, frequencyItems, companyAreas, dayTime }
